 function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import GQLTrebuchetClient, {
  GQLHTTPClient,

} from '@mattkrick/graphql-trebuchet-client'
import getTrebuchet, {SSETrebuchet, SocketTrebuchet} from '@mattkrick/trebuchet-client'
import EventEmitter from 'eventemitter3'
import jwtDecode from 'jwt-decode'


import {


  Environment,




  Network,

  Observable,

  RecordSource,
  RelayFeatureFlags,

  Store,



  fetchQuery
} from 'relay-runtime'




import handleInvalidatedSession from './hooks/handleInvalidatedSession'

import {LocalStorageKey, TrebuchetCloseReason} from './types/constEnums'
import handlerProvider from './utils/relay/handlerProvider'
import sleep from './utils/sleep'
;(RelayFeatureFlags ).ENABLE_RELAY_CONTAINERS_SUSPENSE = false
;(RelayFeatureFlags ).ENABLE_PRECISE_TYPE_REFINEMENT = true





















const noop = () => {
  /* noop */
}

const toFormData = (body, formData = new FormData()) => {
  const uploadables = (body.payload.uploadables || []) 
  delete body.payload.uploadables
  formData.append('body', JSON.stringify(body))
  Object.keys(uploadables).forEach((key) => {
    formData.append(`uploadables.${key}`, uploadables[key ] )
  })
  return formData
}












const store = new Store(new RecordSource(), {gcReleaseBufferSize: 10000})

export default class Atmosphere extends Environment {
  static __initStatic() {this.getKey = (name, variables) => {
    return JSON.stringify({name, variables})
  }}
  

  
  
  __init() {this.authToken = null}
  __init2() {this.authObj = null}
  __init3() {this.querySubscriptions = []}
  __init4() {this.queryTimeouts

 = {}}
  __init5() {this.retries = new Set()}
  __init6() {this.subscriptions = {}}
  // Our server only sends a single field per subscription, but relay requires every requested field
  // This object makes the server response whole without increasing the payload size
  __init7() {this.subscriptionInterfaces = {} }
  __init8() {this.eventEmitter = new EventEmitter()}
  __init9() {this.queryCache = {} }
  __init10() {this.upgradeTransportPromise = null}
  // it's only null before login, so it's just a little white lie
  __init11() {this.viewerId = null}
  
  constructor() {
    super({
      store,
      handlerProvider,
      network: Network.create(noop)
    });Atmosphere.prototype.__init.call(this);Atmosphere.prototype.__init2.call(this);Atmosphere.prototype.__init3.call(this);Atmosphere.prototype.__init4.call(this);Atmosphere.prototype.__init5.call(this);Atmosphere.prototype.__init6.call(this);Atmosphere.prototype.__init7.call(this);Atmosphere.prototype.__init8.call(this);Atmosphere.prototype.__init9.call(this);Atmosphere.prototype.__init10.call(this);Atmosphere.prototype.__init11.call(this);Atmosphere.prototype.__init12.call(this);Atmosphere.prototype.__init13.call(this);Atmosphere.prototype.__init14.call(this);Atmosphere.prototype.__init15.call(this);Atmosphere.prototype.__init16.call(this);Atmosphere.prototype.__init17.call(this);Atmosphere.prototype.__init18.call(this);Atmosphere.prototype.__init19.call(this);Atmosphere.prototype.__init20.call(this);Atmosphere.prototype.__init21.call(this);Atmosphere.prototype.__init22.call(this);Atmosphere.prototype.__init23.call(this);Atmosphere.prototype.__init24.call(this);Atmosphere.prototype.__init25.call(this);
    this._network = Network.create(this.handleFetch, this.handleSubscribe) 
    this.baseHTTPTransport = this.transport = new GQLHTTPClient(this.fetchHTTP)
  }

  __init12() {this.fetchPing = async (connectionId) => {
    return fetch('/sse-ping', {
      headers: {
        'x-application-authorization': `Bearer ${this.authToken}`,
        'x-correlation-id': connectionId || ''
      }
    })
  }}

  __init13() {this.fetchReliable = async (connectionId, data) => {
    return fetch('/sse-ping', {
      method: 'POST',
      headers: {
        'x-application-authorization': `Bearer ${this.authToken}`,
        'x-correlation-id': connectionId || ''
      },
      body: data
    })
  }}

  __init14() {this.fetchHTTP = async (body, connectionId) => {
    const uploadables = body.payload.uploadables
    const headers = {
      accept: 'application/json',
      'x-application-authorization': this.authToken ? `Bearer ${this.authToken}` : '',
      'x-correlation-id': connectionId || ''
    } 

    /* if uploadables, don't set content type bc we want the browser to set it o*/
    if (!uploadables) headers['content-type'] = 'application/json'
    const res = await fetch('/graphql', {
      method: 'POST',
      headers,
      body: uploadables ? toFormData(body) : JSON.stringify(body)
    })
    const contentTypeHeader = res.headers.get('content-type') || ''
    if (contentTypeHeader.toLowerCase().startsWith('application/json')) {
      const resJson = await res.json()
      return resJson
    }
    if (res.status === 401) {
      const text = await res.text()
      if (text === TrebuchetCloseReason.EXPIRED_SESSION) {
        handleInvalidatedSession(TrebuchetCloseReason.EXPIRED_SESSION, {atmosphere: this})
      }
    }
    return null
  }}

  __init15() {this.handleSubscribePromise = async (
    operation,
    variables,
    _cacheConfig,
    sink
  ) => {
    const {name} = operation
    const documentId = operation.id || ''
    const subKey = Atmosphere.getKey(name, variables)
    await this.upgradeTransport()
    const transport = this.transport 
    if (!transport.subscribe) return
    if (!__PRODUCTION__) {
      try {
        const queryMap = await import('../../queryMap.json')
        const query = queryMap[documentId ] 
        this.subscriptions[subKey] = transport.subscribe({query, variables}, sink)
      } catch (e) {
        return
      }
    } else {
      this.subscriptions[subKey] = transport.subscribe({documentId, variables}, sink)
    }
  }}

  __init16() {this.handleSubscribe = (operation, variables, _cacheConfig) => {
    return Observable.create((sink) => {
      const _next = sink.next
      sink.next = (value) => {
        const {data} = value
        const subscriptionName = data ? Object.keys(data)[0] : undefined
        const nullObj = this.subscriptionInterfaces[subscriptionName]
        const nextObj =
          nullObj && subscriptionName
            ? {
                ...value,
                data: {
                  ...data,
                  [subscriptionName]: {
                    ...nullObj,
                    ...data[subscriptionName]
                  }
                }
              }
            : value
        _next(nextObj)
      }
      this.handleSubscribePromise(operation, variables, _cacheConfig, sink).catch(() => {
        /*ignore*/
      })
    })
  }}

  __init17() {this.trySockets = () => {
    const wsProtocol = window.location.protocol.replace('http', 'ws')
    const getUrl = () => {
      this.setAuthToken(this.authToken)
      if (!this.authToken) {
        this.eventEmitter.emit('addSnackbar', {
          autoDismiss: 0,
          key: 'cannotConnectJWT',
          message: 'Session expired. Please refresh to continue',
          action: {
            label: 'Refresh',
            callback: () => {
              window.location.reload()
            }
          }
        })
        return ''
      }
      const host = __PRODUCTION__
        ? window.location.host
        : `${window.location.hostname}:${__SOCKET_PORT__}`
      return `${wsProtocol}//${host}/?token=${this.authToken}`
    }
    return new SocketTrebuchet({getUrl})
  }}

  __init18() {this.trySSE = () => {
    const getUrl = () => `/sse/?token=${this.authToken}`
    return new SSETrebuchet({
      getUrl,
      fetchData: this.fetchHTTP,
      fetchPing: this.fetchPing,
      fetchReliable: this.fetchReliable
    })
  }}

  async promiseToUpgrade() {
    const trebuchets = [this.trySockets, this.trySSE]
    const trebuchet = await getTrebuchet(trebuchets)
    if (!trebuchet) {
      this.eventEmitter.emit('addSnackbar', {
        autoDismiss: 0,
        key: 'cannotConnect',
        message:
          'Cannot establish connection. Behind a firewall? Reach out for support: love@parabol.co'
      })
      console.error('Cannot connect!')
      // this may be reached if the auth token was deemed invalid by the server
      this.setAuthToken(null)
      window.location.href = '/'
      return
    }
    this.transport = new GQLTrebuchetClient(trebuchet)
    this.eventEmitter.emit('newSubscriptionClient')
  }

  async upgradeTransport() {
    // wait until the first and only upgrade has completed
    if (!this.upgradeTransportPromise) {
      this.upgradeTransportPromise = this.promiseToUpgrade()
    }
    return this.upgradeTransportPromise
  }

  __init19() {this.handleFetchPromise = async (
    request,
    variables,
    cacheConfig,
    uploadables,
    sink
  ) => {
    // await sleep(1000)
    const field = __PRODUCTION__ ? 'documentId' : 'query'
    let data = request.id
    if (!__PRODUCTION__) {
      try {
        const queryMap = await import('../../queryMap.json').catch(() => {
          /*ignore*/
        })
        data = queryMap[request.id ] 
      } catch (e) {
        return
      }
      if (!data) console.error('QueryMap is incomplete. Run `yarn clean`')
    }
    const transport = uploadables ? this.baseHTTPTransport : this.transport
    const errorCheckerSink = sink
      ? {
          ...sink,
          next: (value) => {
            if (value.errors) {
              console.error(value.errors)
            }
            return sink.next(value)
          }
        }
      : undefined
    return transport.fetch(
      {
        [field]: data,
        variables,
        cacheConfig,
        uploadables: uploadables || undefined
      },
      // if sink is nully, then the server doesn't send a response
      errorCheckerSink
    )
  }}

  __init20() {this.handleFetch = (request, variables, cacheConfig, uploadables) => {
    return Observable.create((sink) => {
      this.handleFetchPromise(request, variables, cacheConfig, uploadables, sink)
    })
  }}

  __init21() {this.fetchQuery = async ( 
    taggedNode,
    variables = {},
    cacheConfig



  ) => {
    try {
      const res = await fetchQuery(
        this,
        taggedNode,
        variables,
        _nullishCoalesce(cacheConfig, () => ( {
          fetchPolicy: 'store-or-network'
        }))
      ).toPromise()
      return res
    } catch (e) {
      return e 
    }
  }}
  __init22() {this.getAuthToken = (global) => {
    if (!global) return
    const authToken = global.localStorage.getItem(LocalStorageKey.APP_TOKEN_KEY)
    this.setAuthToken(authToken)
  }}

   __init23() {this.validateImpersonation = async (iat) => {
    const isAnotherParabolTabOpen = async () => {
      const askOtherTabs = new Promise((resolve) => {
        if (!this.tabCheckChannel) {
          this.tabCheckChannel = new BroadcastChannel('tabCheck')
        }
        const tabCheckChannel = this.tabCheckChannel
        tabCheckChannel.onmessage = (event) => {
          if (event.data === 'ping') {
            tabCheckChannel.postMessage('pong')
          }
          if (event.data === 'pong') {
            resolve(true)
          }
        }
        tabCheckChannel.postMessage('ping')
      })
      return Promise.race([sleep(300), askOtherTabs])
    }
    const justOpened = iat > Date.now() / 1000 - 10

    const anotherTabIsOpen = await isAnotherParabolTabOpen()
    if (anotherTabIsOpen || justOpened) return
    this.authToken = null
    this.authObj = null
    window.localStorage.removeItem(LocalStorageKey.APP_TOKEN_KEY)
    // since this is async, useAuthRoute will have already run
    window.location.href = '/'
  }}

  __init24() {this.setAuthToken = async (authToken) => {
    this.authToken = authToken || null
    if (!authToken) {
      this.authObj = null
      window.localStorage.removeItem(LocalStorageKey.APP_TOKEN_KEY)
      return
    }
    try {
      this.authObj = jwtDecode(authToken)
    } catch (e) {
      this.authObj = null
      this.authToken = null
    }

    if (!this.authObj) return
    const {exp, sub: viewerId, rol, iat} = this.authObj
    if (rol === 'impersonate') {
      this.viewerId = viewerId
      return this.validateImpersonation(iat)
    }
    // impersonation token must be < 10 seconds old (ie log them out automatically)
    if (exp < Date.now() / 1000) {
      this.authToken = null
      this.authObj = null
      window.localStorage.removeItem(LocalStorageKey.APP_TOKEN_KEY)
    } else {
      this.viewerId = viewerId
      window.localStorage.setItem(LocalStorageKey.APP_TOKEN_KEY, authToken)
    }
  }}

  __init25() {this.registerQuery = async (
    queryKey,
    subscription,
    variables,
    router
  ) => {
    window.clearTimeout(this.queryTimeouts[queryKey])
    delete this.queryTimeouts[queryKey]
    await this.upgradeTransport()
    const {key} = subscription
    // runtime error in case relay changes
    if (!key) throw new Error(`Missing name for sub`)
    const subKey = Atmosphere.getKey(key, variables)
    const isRequested = Boolean(this.querySubscriptions.find((qs) => qs.subKey === subKey))
    if (!isRequested) {
      subscription(this, variables, router)
    }
    this.querySubscriptions.push({queryKey, subKey})
  }}

  registerSubscription(subscriptionRequest) {
    const request = _nullishCoalesce((subscriptionRequest ).default, () => ( subscriptionRequest))
    const payload = request.operation.selections[0] 
    const {selections, name} = payload
    const nullObj = Object.fromEntries(selections.map(({name}) => [name, null]))
    this.subscriptionInterfaces[name] = nullObj
  }
  /*
   * When a subscription encounters an error, it affects the subscription itself,
   * the queries that depend on that subscription to stay valid,
   * and the peer subscriptions that also keep that component valid.
   *
   * For example, in my app component A subscribes to 1,2,3, component B subscribes to 1, component C subscribes to 2,4.
   * If subscription 1 fails, then the data for component A and B get removed from the queryCache, since it might be invalid now
   * Subscription 1 gets unsubscribed,
   * Subscription 2 does not because it is used by component C.
   * Subscription 3 does because no other component depends on it.
   * Subscription 4 does not because there is no overlap
   */
  scheduleUnregisterQuery(queryKey, delay) {
    if (this.queryTimeouts[queryKey]) return
    this.queryTimeouts[queryKey] = window.setTimeout(() => {
      this.unregisterQuery(queryKey)
    }, delay)
  }

  /*
   * removes the query & if the subscription is no longer needed, unsubscribes from it
   */
  unregisterQuery(queryKey) {
    window.clearTimeout(this.queryTimeouts[queryKey])
    delete this.queryTimeouts[queryKey]
    // for each query that is no longer 100% supported, find the subs that power them
    const rowsToRemove = this.querySubscriptions.filter((qs) => qs.queryKey === queryKey)
    // rowsToRemove.forEach((qsToRemove) => {
    // the query is no longer valid, nuke it
    // delete this.queryCache[qsToRemove.queryKey]
    // })
    const subsToRemove = Array.from(new Set(rowsToRemove.map(({subKey}) => subKey)))
    this.querySubscriptions = this.querySubscriptions.filter((qs) => qs.queryKey !== queryKey)
    subsToRemove.forEach((subKey) => {
      const unaffectedSub = this.querySubscriptions.find((qs) => qs.subKey === subKey)
      if (!unaffectedSub) {
        _optionalChain([this, 'access', _ => _.subscriptions, 'access', _2 => _2[subKey], 'optionalAccess', _3 => _3.unsubscribe, 'call', _4 => _4()])
      }
    })
  }

  /* When the server wants to end the subscription, it sends a GQL_COMPLETE payload
   * GQL_Trebuchet cleans itself up & calls the onCompleted observer
   * unregisterSub should therefore be called in each subs onCompleted callback
   */
  unregisterSub(name, variables) {
    const subKey = Atmosphere.getKey(name, variables)
    delete this.subscriptions[subKey]
    const rowsToRemove = this.querySubscriptions.filter((qs) => qs.subKey === subKey)
    rowsToRemove.forEach((qsToRemove) => {
      // the query is no longer valid, nuke it
      delete this.queryCache[qsToRemove.queryKey]
    })
    this.querySubscriptions = this.querySubscriptions.filter((qs) => qs.subKey !== subKey)
    // does not remove other subs because they may still do interesting things like pop toasts
  }

  close() {
    this.querySubscriptions.forEach((querySub) => {
      this.unregisterQuery(querySub.queryKey)
    })
    // remove all records
    ;(this.getStore().getSource() ).clear()
    this.upgradeTransportPromise = null
    this.authObj = null
    this.authToken = null
    if (this.transport instanceof GQLTrebuchetClient) {
      this.transport.close()
    }
    this.transport = new GQLHTTPClient(this.fetchHTTP)
    this.querySubscriptions = []
    this.subscriptions = {}
    this.viewerId = null
  }
} Atmosphere.__initStatic();
